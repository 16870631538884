<div class="grid header">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.roadmap.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.roadmap.mainMessage" | transloco }}
        </p>
    </div>
</div>

<div class="sections-container">
    <div class="grid roadmap">
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.history" | transloco }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q2 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.droneTower.steps.historyDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.bvlos" | transloco }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q2 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.droneTower.steps.bvlosDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{
                        "dtmMainPageLib.subpage.roadmap.droneTower.steps.registrationFromApp" | transloco
                    }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q2 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.droneTower.steps.registrationFromAppDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{
                        "dtmMainPageLib.subpage.roadmap.droneTower.steps.mapSettings" | transloco
                    }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q3 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.droneTower.steps.mapSettingsDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{
                        "dtmMainPageLib.subpage.roadmap.droneTower.steps.inactiveZones" | transloco
                    }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q4 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.droneTower.steps.inactiveZonesDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.newLegislation" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.newPansaUtm" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.steps.deployment" | transloco }}
                <div class="estimation">Q2 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.steps.development" | transloco }}
                <div class="estimation">Q4 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.description" | transloco }}
            </p>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.steps.bspTools" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.steps.disableCurrentVersion" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.steps.coordinationTools" | transloco }}
                <div class="estimation">Q1 2026</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.optimization" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q3/Q4 2024</div>
                </div>
            </div>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{
                        "dtmMainPageLib.subpage.roadmap.eLearning.steps.membersInviting" | transloco
                    }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q1 2025</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="'dtmMainPageLib.subpage.roadmap.eLearning.steps.membersInvitingDetails' | transloco"
                ></span>
            </dtm-ui-expandable-panel>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.dedicatedTrainings" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q1 2025</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.processAutomatization" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <dtm-ui-expandable-panel class="expandable-step">
                <div headerSlot>
                    <span class="expandable-step-header">{{
                        "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.planProceedingAutomatization" | transloco
                    }}</span>
                    <div class="status">
                        <span class="chips chips-deployed">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                        </span>
                        <div class="estimation">Q3/Q4 2024</div>
                    </div>
                </div>
                <span
                    class="expandable-step-content"
                    [innerHTML]="
                        'dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.planProceedingAutomatizationDetails' | transloco
                    "
                ></span>
            </dtm-ui-expandable-panel>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.dfpl" | transloco }}
                <div class="estimation">Q2 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.tracking" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q3/Q4 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.remoteId" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q1/Q2 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.phase2" | transloco }}
                <div class="estimation">Q4 2026+</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.eSora.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.eSora.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.feedbackBasedFixes" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q4 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.automaticParameterValidator" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.inopCreate" | transloco }}
                <div class="estimation">Q2 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.steps.policeFeedbackFixes" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q4 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q2 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q1 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">Q1 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.publicInformationAutomatization" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">Q2 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.shFlight" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.pilotPanel" | transloco }}
                <div class="estimation">Q3 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.comms" | transloco }}
                <div class="estimation">Q4 2025</div>
            </div>
        </div>
    </div>
</div>
